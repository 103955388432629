<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:body>
            <div v-show="!fileView.state">
              <b-overlay :show="fileView.loading" rounded="sm">
                <b-row>
                  <b-col>
                    <b-form-select
                      v-model="student_class_selected"
                      :options="student_classes"
                      @change="getClassSubjectsList"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="curriculum_subject_selected"
                      :options="curriculum_subjects"
                      @change="getClassMaterialsList"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-table
                    striped
                    hover
                    show-empty
                    :items="items"
                    :fields="fields"
                    :busy="isBusy"
                    class="mt-3"
                  >
                    <template v-slot:empty="scope">
                      <p class="text-center">
                        {{ $t("CLASS_MATERIALS.NO_MATERIALS_UPLOADED") }}
                      </p>
                    </template>
                    <template v-slot:cell(actions)="{ item }">
                      <b-button
                        pill
                        size="sm"
                        variant="info"
                        v-b-tooltip.hover
                        :title="$t('GENERAL.VIEW_FILE_DESCRIPTION')"
                        @click="loadFile(item.type, item.id)"
                      >
                        <i
                          class="fa fa-eye"
                          style="font-size: 1.3em; margin-left: 0;"
                        ></i>
                      </b-button>
                      &nbsp;&nbsp;
                      <b-button
                        pill
                        size="sm"
                        variant="success"
                        v-b-tooltip.hover
                        :title="$t('GENERAL.DOWNLOAD')"
                        v-if="item.type !== 'Youtube'"
                        @click="downloadFile(item.type, item.id)"
                      >
                        <i
                          class="fa fa-download"
                          style="font-size: 1.3em; margin-left: 0;"
                        ></i>
                      </b-button>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </b-row>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="current_page"
                      :total-rows="total_rows"
                      :per-page="items_per_page"
                      align="center"
                      @change="getClassMaterialsList"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-overlay>
            </div>
            <b-row v-if="fileView.state">
              <b-col>
                <b-button
                  pill
                  size="sm"
                  variant="info"
                  v-b-tooltip.hover
                  :title="$t('GENERAL.BACK')"
                  @click="unLoadFile"
                  class="kt-margin-b-10"
                >
                  <i
                    class="fa fa-angle-left"
                    style="font-size: 1.3em; margin-left: 0;"
                  ></i>
                  {{ $t("GENERAL.BACK") }}
                </b-button>
                <FilePreview
                  :type="fileView.type"
                  :path="fileView.url"
                  :id="fileView.id"
                  :model="fileView.model"
                  discussion="true"
                />
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import FilePreview from "@/views/pages/FilePreview";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      title: this.$t("MENU.CLASS_MATERIALS"),
      isBusy: true,
      materials: "materials",
      fileView: {
        loading: false,
        state: false,
        type: null,
        model: "student-class-shared-file",
        url: null,
        id: null
      },
      fields: [
        { semester: this.$t("GENERAL.SEMESTER") },
        { subject: this.$t("GENERAL.SUBJECT") },
        { lecturer: this.$t("GENERAL.LECTURER") },
        { upload_time: this.$t("CLASS_MATERIALS.UPLOAD_TIME") },
        { file_name: this.$t("GENERAL.FILE_NAME") },
        { type: this.$t("GENERAL.FILE_TYPE") },
        { size: this.$t("GENERAL.FILE_SIZE") },
        { actions: this.$t("GENERAL.ACTIONS") }
      ],
      items: [],
      student_classes: [],
      student_class_selected: 0,
      curriculum_subjects: [
        { value: 0, text: this.$t("GENERAL.ALL_SUBJECTS") }
      ],
      curriculum_subject_selected: 0,
      current_page: 1,
      total_rows: 1,
      items_per_page: 1
    };
  },
  components: {
    KTPortlet,
    FilePreview
  },
  methods: {
    getClassSubjectsList() {
      this.isBusy = true;
      ApiService.get(
        "student/enrolled-class-subjects/" + this.student_class_selected
      ).then(({ data }) => {
        this.curriculum_subjects = data;
        this.curriculum_subjects.unshift({
          value: 0,
          text: this.$t("GENERAL.ALL_SUBJECTS")
        });
        this.isBusy = false;
        this.getClassMaterialsList();
      });
    },
    getClassMaterialsList() {
      this.isBusy = true;
      let filters = {
        student_class_id: this.student_class_selected,
        curriculum_subject_id: this.curriculum_subject_selected
      };
      ApiService.post(
        "student/class-materials?page=" + this.current_page,
        filters
      ).then(({ data }) => {
        this.items = data.items;
        this.items_per_page = data.items_per_page;
        this.total_rows = data.total_rows;
        this.isBusy = false;
      });
    },
    loadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/load/student-class-shared-file/" + id)
        .then(({ data }) => {
          this.fileView.state = true;
          this.fileView.type = type;
          this.fileView.url = data;
          this.fileView.id = id;
          this.fileView.loading = false;
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    },
    unLoadFile() {
      this.fileView.state = false;
      this.fileView.type = null;
      this.fileView.url = null;
      this.fileView.id = null;
    },
    downloadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/download/student-class-shared-file/" + id)
        .then(({ data }) => {
          this.fileView.loading = false;
          window.open(data, "_blank");
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.CLASS_MATERIALS"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("student/enrolled-classes/class-materials").then(
      ({ data }) => {
        this.student_classes = data.student_classes;
        this.student_class_selected =
          data.student_classes[0] !== undefined
            ? this.student_classes[0].value
            : 0;
        this.curriculum_subjects = data.curriculum_subjects;
        if (data.curriculum_subjects[0] !== undefined)
          this.curriculum_subjects.unshift({
            value: 0,
            text: this.$t("GENERAL.ALL_SUBJECTS")
          });
        this.getClassMaterialsList();
      }
    );
  },
  watch: {
    student_class_selected: function() {
      this.current_page = 1;
    },
    curriculum_subject_selected: function() {
      this.current_page = 1;
    }
  }
};
</script>
